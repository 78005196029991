<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs3 text-left>
            <span class="mainHeader"> ESTIMATE</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-6 text-left>
        <!---------------------ESTIMATE DETAILS------------------------->

        <v-layout wrap>
          <v-flex xs12 >
            <v-card
              tile
              elevation="0"
              class=""
              :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <!---------------------Table-------------------------->
              <v-layout px-4 py-4 wrap>
                <v-flex xs12>
                  <v-card outlined class="pa-4">
                    <v-layout wrap>
                      <v-flex xs12 v-if="itemz">
                        <v-simple-table>
                          <template v-slot:default>
                            <thead style="text-transform: uppercase;">
                              <tr>
                                <th class="text-left subhed"><b>S.No.</b></th>
                                <th class="text-left subhed">
                                  <b>Product Name</b>
                                </th>
                                <th class="text-left subhed"><b>HSN</b></th>
                                <th class="text-left subhed">
                                  <b>Quantity</b>
                                </th>
                                <th class="text-left subhed"><b>Gr.wt</b></th>
                                <th class="text-left subhed"><b>St.wt</b></th>
                                <th class="text-left subhed"><b>Net.wt</b></th>
                                <th class="text-left subhed"><b>St.pr</b></th>
                                <th class="text-left subhed"><b>MK Chg</b></th>
                                <th class="text-left subhed"><b>VA %</b></th>
                                <th class="text-left subhed"><b>Total</b></th>
                                <th class="text-left subhed"><b>Action</b></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(value, i) in itemz"
                                :key="i"
                                class="table"
                              >
                                <!-- <td>{{ i + 1 }}</td> -->
                                <td>
                                  <span>
                                    {{ i + 1 }}
                                  </span>
                                </td>
                                <td>
                                  <span v-if="value.itemCode">{{
                                    value.itemCode
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.category">
                                    {{ value.category }}</span
                                  >
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.quantity">{{
                                    value.quantity
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.boardingRate">{{
                                    value.boardingRate
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.makingCharge">{{
                                    value.makingCharge
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 class="subhed2">
                  <v-layout wrap justify-end py-2>
                    <v-flex xs2 text-right pr-4 align-self-center>
                      Tax :
                    </v-flex>
                    <v-flex xs2 pr-5>
                      <v-text-field
                        autofocus
                        v-model="tax"
                        outlined
                        color="#b234a9"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                  <v-layout wrap justify-end py-2>
                    <v-flex xs2 text-right pr-4 align-self-center>
                      Sale Weight :
                    </v-flex>
                    <v-flex xs2 pr-5>
                      <v-text-field
                        autofocus
                        v-model="saleweight"
                        outlined
                        color="#b234a9"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                  <v-layout wrap justify-end py-2 pb-4>
                    <v-flex xs2 text-right pr-4 align-self-center>
                      Sale Amount :
                    </v-flex>
                    <v-flex xs2 pr-5>
                      <v-text-field
                        autofocus
                        v-model="saleamount"
                        outlined
                        color="#b234a9"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <!----------------------------------olg Gold-------------------------->
        <v-layout wrap>
          <v-flex xs12>
            <v-card
              tile
              elevation="0"
             
              :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-layout px-4 py-4 wrap>
                <v-flex xs12 py-2 pl-2 class="mainHeader2"> Old Gold </v-flex>

                <v-flex xs12>
                  <v-card outlined class="pa-4">
                    <v-layout wrap>
                      <v-flex xs12 v-if="itemz">
                        <v-simple-table>
                          <template v-slot:default>
                            <thead style="text-transform: uppercase;" >
                              <tr>
                                <th class="text-left subhed">
                                  <b>S.No.</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>Item</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>Gross Weight</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>Less Weight </b>
                                </th>
                                <th class="text-left subhed">
                                  <b>Purity</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>Stone Price</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>Amount</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>Action</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(value, i) in olditems"
                                :key="i"
                                class="table"
                              >
                                <!-- <td>{{ i + 1 }}</td> -->
                                <td>
                                  <span>
                                    {{ i + 1 }}
                                  </span>
                                </td>
                                <td>
                                  <span v-if="value.itemCode">{{
                                    value.itemCode
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.category">
                                    {{ value.category }}</span
                                  >
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.quantity">{{
                                    value.quantity
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.boardingRate">{{
                                    value.boardingRate
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.makingCharge">{{
                                    value.makingCharge
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 class="subhed2">
                  <v-layout wrap justify-end py-2>
                    <v-flex xs2 text-right pr-4 align-self-center>
                      Total :
                    </v-flex>
                    <v-flex xs2 pr-5>
                      <v-text-field
                        autofocus
                        v-model="oldfinalTotal"
                        outlined
                        color="#b234a9"
                        dense
                        disabled
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>

        <!----------------------------------Return-------------------------->

        <v-layout wrap>
          <v-flex xs12 >
            <v-card
              tile
              elevation="0"
              class="pb-4"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-layout px-4 py-4 wrap>
                <v-flex xs12 py-2 pl-2 class="mainHeader2"> Return </v-flex>
                <v-flex xs12>
                  <v-card outlined class="pa-4">
                    <v-layout wrap>
                      <v-flex xs12 v-if="itemz">
                        <v-simple-table>
                          <template v-slot:default>
                            <thead style="text-transform: uppercase;" >
                              <tr>
                                <th class="text-left subhed">
                                  <b>S.No.</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>Product Name</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>HSN</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>Count</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>Gr.wt</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>St.wt</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>Net.wt</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>St.pr</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>GST</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>VA %</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>Total</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>Action</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(value, i) in itemz"
                                :key="i"
                                class="table"
                              >
                                <!-- <td>{{ i + 1 }}</td> -->
                                <td>
                                  <span>
                                    {{ i + 1 }}
                                  </span>
                                </td>
                                <td>
                                  <span v-if="value.itemCode">{{
                                    value.itemCode
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.category">
                                    {{ value.category }}</span
                                  >
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.quantity">{{
                                    value.quantity
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.boardingRate">{{
                                    value.boardingRate
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.makingCharge">{{
                                    value.makingCharge
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 class="subhed2">
                  <v-layout wrap justify-end py-2>
                    <v-flex xs2 text-right pr-4 align-self-center>
                      Sale return weight :
                    </v-flex>
                    <v-flex xs2 pr-5>
                      <v-text-field
                        autofocus
                        v-model="oldfinalTotal"
                        outlined
                        color="#b234a9"
                        dense
                        disabled
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                  <v-layout wrap justify-end py-2>
                    <v-flex xs2 text-right pr-4 align-self-center>
                      Sale return price :
                    </v-flex>
                    <v-flex xs2 pr-5>
                      <v-text-field
                        autofocus
                        v-model="oldfinalTotal"
                        outlined
                        color="#b234a9"
                        dense
                        disabled
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>

        <!---------------------Summary-------------------------->
        
        
        <v-layout wrap>
          <v-flex xs12 >
            <v-card
              tile
              elevation="0"
              
              :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
            <v-divider class="pb-4" ></v-divider>
              <v-layout wrap>
                <v-flex xs12 class="subhed2">
                  <v-layout wrap>
                    <v-flex xs12 pl-4 pt-4 style="font-size: 20px">
                      Summary
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-end py-2>
                    <v-flex xs2 text-right pr-4 align-self-center>
                      Advance :
                    </v-flex>
                    <v-flex xs2 pr-5>
                      <v-text-field
                        autofocus
                        outlined
                        disabled
                        color="#b234a9"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                  <v-layout wrap justify-end py-2 pb-6>
                    <v-flex xs3 text-right pr-4 align-self-center>
                      Total Payable Amount :
                    </v-flex>
                    <v-flex xs2 pr-5>
                      <v-text-field
                        autofocus
                        outlined
                        disabled
                        color="#b234a9"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-flex xs12 pr-4>
            <v-layout wrap justify-end py-4>
              <!-- <v-flex xs3 pa-1>
                <v-btn dark color="#3F053C" block @click="submit()"
                  >Generate Quotation</v-btn
                ></v-flex
              > -->
              <v-flex xs2 pa-2>
                <v-btn
                  dark
                  color="#3F053C"
                  block
                  @click="$router.push('/payment')"
                  >Generate Estimate</v-btn
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      quotation: "1",
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      estno: null,
      date: null,
      menu2: false,
      customerName: null,
      address: null,
      city: null,
      contact: null,
      netweight: null,
      salesmenCode: null,
      itemCode: null,
      category: null,
      va: null,
      quantity: null,
      boardingRate: null,
      makingCharge: null,
      singleitem: {
        itemCode: null,
        category: null,
        quantity: null,
        boardingRate: null,
        city: null,
        makingCharge: null,
        olditemCode: null,
      },
      purity: null,
      oldstoneweight: null,
      oldstoneprice: null,
      oldlessweight1: null,
      oldlessweight2: null,
      oldgrossweight: null,
      oldnetweight: null,
      oldprice: null,
      oldtotal: null,
      tax: null,
      saleweight: null,
      saleamount: null,
      oldfinalTotal: null,
      returnbillno: null,
      returndate: null,

      itemz: [],
      olditems: [],
      categoryList: [],
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    validateInput() {
      if (!this.email) {
        this.msg = "Please provide email";
        this.showsnackbar = true;
        return;
      } else if (!this.name) {
        this.msg = "Please provide name";
        this.showsnackbar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please provide phone";
        this.showsnackbar = true;
        return;
      } else if (!this.username) {
        this.msg = "Please provide username";
        this.showsnackbar = true;
        return;
      } else if (!this.address) {
        this.msg = "Please provide address";
        this.showsnackbar = true;
        return;
      } else {
        this.addAdmin();
      }
    },
    addValues() {
      this.singleitem.itemCode = this.itemCode;
      this.singleitem.category = this.category;
      this.singleitem.quantity = this.quantity;
      this.singleitem.boardingRate = this.boardingRate;
      this.singleitem.city = this.city;
      this.singleitem.makingCharge = this.makingCharge;
      this.addVariant();
    },

    addVariant() {
      this.itemz.push(JSON.parse(JSON.stringify(this.singleitem)));
      console.log("itemz=", this.itemz);
      //////
      this.itemCode = null;
      this.category = null;
      this.quantity = null;
      this.boardingRate = null;
      this.city = null;
      this.makingCharge = null;
      //   this.check = false;
    },
    addVariant1() {
      this.olditems.push(JSON.parse(JSON.stringify(this.singleitem)));
      console.log("olditems=", this.olditems);
      //////

      //   this.check = false;
    },

    getCategory() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/category/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          count: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.categoryList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    // addAdmin() {
    //   axios({
    //     url: "/user/jewelleryregister",
    //     method: "POST",
    //     data: {
    //       username: this.username,
    //       email: this.email,
    //       jewelleryName: this.name,
    //       phone: this.phone,
    //       location: this.address,
    //     },
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //         this.email = null;
    //         this.phone = null;
    //         this.name = null;
    //         this.address = null;
    //         this.username = null;
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
  },
};
</script>
